
.toolbar {
    grid-row: toolbar;

    display: flex;
    flex-direction: row;
    background-color: #9CC6EA;
    width: 100vw;
    overflow: hidden;
}

.toolbar-item {
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.base-block {
    display: flex;
    flex-direction: row;
    user-select: none;
    background-color: #C4DEF5;
    height: 130px;
    width: 347px;
    padding-top: 0.625rem;
    padding-left: 1.5rem;
}

.base-block-container {
    margin-right: 0.625rem;
    max-width: 17.5rem;
}

.base-block p {
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: #143D66;
}

.slider-container {
    display: flex;
    margin-bottom: 5px;
}

.slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
    margin-top: 0;
    min-width: 30px;
}

.lang-title {
    margin: 0;
    margin-bottom: 5px;
    height: 24px;
    line-height: 24px;
    width: 66px;
}

.mode-title {
    margin: 0;
    margin-bottom: 5px;
    height: 30px;
    line-height: 30px;
}

.time-title {
    margin: 0;
    margin-bottom: 5px;
    height: 24px;
    line-height: 24px;
}

.scale-title {
    margin: 0;
    height: 24px;
    line-height: 24px;
}

.lang-selector-container {
    width: 45px;
}

.lang-current {
    font-size: 14px;
    line-height: 24px;
    color: #143D66;
    width: 35px;
    margin-left: 10px;
}

.lang-current span, .lang-selector-active-wrapper span {
    display: inline-block;
    transform: scaleY(0.5);
    line-height: 10px;
}

.lang-selector-active-wrapper {
    background-color: #C4DEF5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    margin-top: -1px;
    padding-bottom: 3px;
    padding-left: 9px;
    font-size: 14px;
    line-height: 24px;
    color: #143D66;
    border: 1px solid #144066;
    border-bottom: 0;
    width: 35px;
    z-index: 300;
}

.lang-selector-item-container {
    background-color: #C4DEF5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    margin-top: 0;
    padding: 0 9px;
    border: 1px solid #144066;
    width: 26px;
    z-index: 200;
}

.lang-selector-item {
    list-style-type: none;
    margin: 6px 0;
    padding: 0;
    color: #144066;
    font-weight: normal;
}

.lang-selector-item:hover {
    font-weight: 900;
}

.editor-mode-switch {
    height: 30px;
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    border-radius: 4px;
    background-color: #9CC6EA;
}

.editor-mode-item {
    cursor: pointer;
    height: 24px;
    width: 24px;
    border-radius: 4px;
}

.editor-mode-item-redacting {
    margin: 3px;
}

.editor-mode-item-active {
    background-color: #FAFEFF;
    border-radius: 4px;
}

.arrow {
  stroke:#144066;
  stroke-width:2px;
  stroke-linecap: round;
}

.arrow:hover {
    stroke:#FF983D;
}

.quick-launch-bar {
    background-color: #C4DEF5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 128px;
    margin-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 5px;
    width: 70px;
}

.quick-launch-bar-item {
    margin: 5px 0 5px 5px;
}

.quick-launch-bar-item .quick-launch-bar-icon-stroke {
    stroke: #144066;
}

.quick-launch-bar-item:hover .quick-launch-bar-icon-stroke {
    stroke: #FF983D;
}

.quick-launch-bar-item .quick-launch-bar-icon-fill {
    fill: #144066;
}

.quick-launch-bar-item:hover .quick-launch-bar-icon-fill {
    fill: #FF983D;
}


.tool-selector {
    user-select: none;
    overflow: hidden;
    height: 140px;
    flex-basis: auto;
    width: calc(100% - 451px);
}

.toolbar-tab-title-container {
    background-color: #9CC6EA;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.toolbar-tab-title {
    background-color: #9CC6EA;
    color: #144066;
    cursor: pointer;
    padding: 0.375rem 1.25rem;
}

.toolbar-tab-title:not(.toolbar-tab-title-active+.toolbar-tab-title):not(:first-child) {
    padding-left: 0;
}

.toolbar-tab-title:not(.toolbar-tab-title-active+.toolbar-tab-title):not(:first-child)::before {
    content: "|";
    font-size: 1.25rem;
    line-height: 0.875rem;
    display: inline-block;
    transform: scaleX(0.5);
    margin-right: 1.25rem;
}

.toolbar-tab-title-active {
    background-color: #C4DEF5;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    color: #144066;
    cursor: pointer;
    padding: 0.375rem 1.25rem;
}

.toolbar-tab-content-loader-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 8.75rem;
    width: 100%;
}

.toolbar-tab-content-loader-title {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    margin-left: 1rem;
}

.toolbar-tab-content-wrapper {
    align-items: center;
    background-color: #C4DEF5;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.toolbar-tab-content-container {
    align-items: center;
    /* border-top-left-radius: 0.625rem; */
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 2;
    margin: 0.625rem 0 0.625rem 0;
    overflow: hidden;
}

.toolbar-partition {
    align-items: flex-start;
    background: #FAFEFF;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 5.625rem;
    margin-right: 0.1875rem;
    min-width: 3.125rem;
    overflow: hidden;
}

.toolbar-partition-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.toolbar-partition-bottom-container-complicated {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    width: 100%;
    z-index: 0;
}

.tool-arrow {
    stroke:#9CC6EA;
    stroke-linecap: round;
    stroke-width: 0.125rem;
}

.tool-arrow:hover {
    stroke:#FF983D;
}

.arrow-next-tool, .arrow-prev-tool {
    background-color: transparent;
    height: 0.75rem;
    margin: 0.375rem 0.625rem 0.625rem;
    width: 0.75rem;
}

.toolbar-partition-title {
    color: #143D66;
    font-size: 0.875rem;
    font-family: Roboto, sans-serif;
    line-height: 1.5rem;
    margin: 0;
    pointer-events: none;
    white-space: nowrap;
}

.toolbar-partition-resizer {
    cursor: url(../../../studio/images/toolBar/icon-resize-partition-cursor.svg) 12 10, auto;
    height: 2.125rem;
    margin-right: 0.1875rem;
    min-width: 0.25rem;
    pointer-events: all;
    width: 0.25rem;
}

.toolbar-partition-item-container {
    display: flex;
    flex-direction: row;
    margin: 0 0.5rem;
}

.toolbar-tool {
    margin-left: 0.3125rem;
    height: 2.5rem;
    width: 2.5rem;
}

.toolbar-tool-wrapper {
    background: #FAFEFF;
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0.625rem 0.125rem 0.3125rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

.toolbar-tool-wrapper:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.5rem 0 0.1875rem;
}

.toolbar-tool-wrapper-subpartition {
    background: #FAFEFF;
    border-radius: 0.625rem;
    height: 2.8125rem;
    margin: 0.3125rem;
    padding-top: 0.3125rem;
    width: 3.125rem;
}

.toolbar-tool-wrapper-subpartition:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.1875rem;
}

.toolbar-subpartition {
    height: 2.5rem;
    margin: 0.3125rem 0.3125rem 0;
    width: 2.5rem;
}

.toolbar-subpartition-preview-container {
    border-radius: 0.625rem;
    height: 3.75rem;
    margin: 0.3125rem 0.125rem 0;
    outline: none;
    width: 3.125rem;
}

.toolbar-subpartition-preview-container:hover {
    border: 0.125rem solid #FF983D;
    margin: 0.1875rem 0 -0.125rem;
}

.toolbar-subpartition-active {
    height: 2.5rem;
    margin: 0.3125rem 0.3125rem 0;
    pointer-events: none;
    width: 2.5rem;
}

.toolbar-subpartition-image {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-bottom: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    height: 3.875rem;
    margin-top: 0.1875rem;
    position: absolute;
    width: 3.25rem;
    z-index: 300;
}

.toolbar-subpartition-container {
    background-color: #C4DEF5;
    border: 0.0625rem solid #144066;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 11.25rem;
    outline: none;
    padding: 0.3125rem;
    position: absolute;
    z-index: 200;
}

.toolbar-subpartition-title {
    color: #143D66;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: center;
    width: 100%;
    /*перенос по словам, но если не помещается, то и слово разорвать можно*/
    word-break: break-word;
    overflow-wrap: anywhere;
    outline: none;
}

.toolbar-subpartition-arrow {
    stroke: #143D66;
    stroke-width: 0.125rem;
    stroke-linecap: round;
}

.extra-margin {
    margin: 0.4375rem;
}

.extra-arrow-margin {
    margin: 0.75rem;
}

.partition-arrow {
    stroke-width: 0.125rem;
    stroke-linecap: round;
    stroke: #144066;
}

.partition-arrow:hover {
    stroke: #FF983D;
}

.arrow-next-partition {
    height: 0.875rem;
    margin-left: 0.125rem;
    margin-right: 0.0625rem;
    max-width: 0.5rem;
    min-width: 0.5rem;
}

.arrow-prev-partition {
    height: 0.875rem;
    margin-left: 0.125rem;
    margin-right: 0.0625rem;
    max-width: 0.5rem;
    min-width: 0.5rem;
}
