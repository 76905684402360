
.survey-p {
    color: rgba(20, 64, 102, 1);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    max-width: 500px;
    text-align: center;
}

.survey-p-bold {
    color: rgba(20, 64, 102, 1);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    max-width: 500px;
    text-align: center;
    font-weight: bold;
}

.survey-answer-radio-wrapper {
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.survey-answer-radio-hidden-std {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.survey-answer-radio-label {
    color: rgba(20, 64, 102, 1);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
}

.survey-answer-radio-hidden-std+.survey-answer-radio-label::before {
    background-color: #9CC6EA;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.7rem;
    width: 1rem;
}

.survey-answer-radio-hidden-std:checked+.survey-answer-radio-label::before {
    background-color: #144066;
    border: 0.2rem solid #9CC6EA;
    height: 0.6rem;
    width: 0.6rem;
}

input[type="number"].survey-answer-number-input {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number].survey-answer-number-input::-webkit-inner-spin-button,
input[type=number].survey-answer-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.survey-answer-number-container {
    display: flex;
    flex-direction: row;
}

.survey-answer-number-button-container {
    display: flex;
    flex-direction: column;
}

.survey-answer-number-input {
    background: #9CC6EA;
    border: 1px solid #144066;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #144066;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    outline: none;
    padding-left: 6px;
    width: 50px;
}

.survey-answer-number-button {
    background: #9CC6EA;
    border: none;
    border-right: 1px solid #144066;
    color: #144066;
    height: 14.5px;
    font-weight: normal;
    font-style: normal;
    font-size: 10px;
    line-height: 11px;
    outline: none;
    padding: 1px 4px;
}

.survey-answer-number-button-increase {
    border-top: 1px solid #144066;
    border-top-right-radius: 10px;
}

.survey-answer-number-button-decrease {
    border-bottom: 1px solid #144066;
    border-bottom-right-radius: 10px;
}


.survey-answer-text-input {
    background: #9CC6EA;
    border: 1px solid #144066;
    border-radius: 10px;
    color: #144066;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    outline: none;
    padding: 1px 6px;
    width: 400px;
}

.survey-answer-rating-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 150px;
}

.survey-answer-rating-item {
    align-items: center;
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    height: 50px;
    justify-content: center;
    line-height: 25px;
    user-select: none;
    width: 20px;
}

.survey-finish-title {
    margin-bottom: 100px;
    margin-top: 100px;
}

.survey-dropdown {
    position: relative;
    display: inline-block;
}

.survey-answers-container {
    background-color: #9CC6EA;
    border-radius: 10px;
    display: none;
    max-height: 400px;
    overflow-y: hidden;
    padding: 5px 10px;
    position: absolute;
    width: 395px;
    z-index: 1;
}

.survey-dropdown-opened .survey-answers-container {
    display: block;
}

.survey-possible-answer {
    border-radius: 10px;
    color: #144066;
    margin: 5px;
    padding: 5px;
    user-select: none;
}

.survey-possible-answer-active {
    background-color: #144066;
    border-radius: 10px;
    color: #C4DEF5;
    margin: 5px;
    padding: 5px;
    user-select: none;

}
