.btn {
    background: #144066;
    border: none;
    border-radius: 0.625rem;
    color: #FAFEFF;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 2.75rem;
    margin-bottom: 0.375rem;
    text-transform: uppercase;
}

.btn:focus {
    outline: none;
}
