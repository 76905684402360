.account-container, .account-icons-container {
    background-color: #FAFEFF;
    display: flex;
    min-height: 100%;
    padding: 0 5.625rem 2.5rem;
}

.account-user-info-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 14.375rem;
}

.account-user-name {
    color: #144066;
    display: block;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    max-width: 40rem;
    overflow: hidden;
    padding: 1.875rem 0 0.875rem;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;
    width: 100%;
}

.account-user-name-black {
    color: #0F1D23;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin-top: 0.625rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
}

.account-avatar-wrapper {
    height: 9.75rem;
    width: 13.75rem;
}

.account-container label {
    color: #0F1D23;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    margin-top: 0.625rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
}

.account-user-restricted-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.25rem;
    width: 100%;
}

.account-user-restricted-info-container>span {
    color: #122E73;
    cursor: pointer;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-top: 1rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
}

.account-user-restricted-info-container>span:hover {
    text-decoration: underline;
}

.account-studio-arrow {
    display: inline-block;
    font-weight: 100;
    transform: scale(1.5, 1) translate(0, 3%);
    opacity: 0.7;
}

.account-product-container {
    height: 100%;
    margin: 2rem 0 0 20rem;
    width: 100%;
}

.account-product-title {
    color: #144066;
    display: -webkit-flex;
    display: flex;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    line-height: 1.75rem;
    margin: 2.5rem 0 0.75rem;
}

.account-card-button {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0.625rem 0 0;
}

.account-icons-sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    width: 18.125rem;
}

.account-icons-categories-container {
    display: flex;
    flex-direction: column;
}

.account-icons-category-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    max-height: 16.875rem;
}

.account-icons-category-polygon-title, .account-icons-category-icons-title {
    background-color: #FAFEFF;
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
}

.account-icons-category-icons-title {
    padding-bottom: 0;
}

.account-icons-menu-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.5rem;
    width: 100%;
}

.account-icons-menu-container>span {
    color: #122E73;
    cursor: pointer;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-top: 0.625rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
}

.account-icons-menu-container>span:hover, .account-icons-check-span:hover {
    text-decoration: underline;
}

.account-icons-check-span {
    color: #122E73;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    margin-bottom: 0.625rem;
}

.account-icons-product-icons-container {
    display: grid;
    grid-template-columns: repeat(6, 2.5rem);
    grid-column-gap: 0.375rem;
    grid-row-gap: 0.375rem;
    width: 100%;
}

.account-icons-product-icons-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.retractable-subsection-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.retractable-subsection-content-container {
    display: none;
}

.retractable-subsection-checkbox {
    display: none;
}

.retractable-subsection-checkbox-label {
    align-items: center;
    color: #122E73;
    cursor: pointer;
    display: flex;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    user-select: none;
}

.retractable-subsection-checkbox-label-arrow {
    transform: rotate(180deg);
}

.retractable-subsection-checkbox:checked+label>.retractable-subsection-checkbox-label-arrow {
    transform: rotate(270deg);
}

.retractable-subsection-checkbox:checked+label+.retractable-subsection-content-container {
    display: block;
}



.account-icons-product-icon {
    height: 2.25rem;
    margin: 0;
    width: 2.25rem;
}

.account-icons-info {
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin: 1rem 0 3rem;
}

.account-icons-tab-title-container, .account-icons-tab-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.account-icons-tab-title-container input {
    font-family: Roboto-mono, sans-serif;
}

.account-icons-tab-container {
    margin-bottom: 2rem;
}

.account-icons-add-tab {
    align-items: center;
    border-radius: 0.625rem;
    color: #144066;
    cursor: pointer;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    justify-content: center;
    line-height: 1.25rem;
    padding: 0.3125rem 0.625rem;
    user-select: none;
}

.account-icons-add-tab:hover {
    background-color: #C4DEF5;
}

.account-icons-partition-container {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, 18.375rem);
    grid-row-gap: 1.875rem;

}

.account-icons-partition-wrapper {
    position: relative;
}

.account-icons-partition {
    align-items: center;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 19.75rem;
    justify-content: space-between;
    width: 18.125rem;
    overflow: hidden;
}

.account-icons-add-partition {
    align-items: center;
    border: 0.125rem dashed #144066;
    border-radius: 0.625rem;
    color: #144066;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    height: 19.75rem;
    justify-content: center;
    line-height: 1.25rem;
    width: 18.125rem;
}

.account-icons-add-partition:hover {
    text-decoration: underline;
}

.account-icons-partition-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0.625rem 1rem;
    width: calc(100% - 1.25rem);
}

.account-icons-partition-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    width: calc(100% - 0.625rem);
    word-break: break-all;
    white-space: break-spaces;
}

.account-icons-partition-button:hover, .account-icons-partition-button-active {
    background-color: #C4DEF5;
    border-radius: 0.25rem;
}

.account-icons-partition-button-active:hover {
    background-color: transparent;
    border-radius: 0;
}

.account-icons-partition-hint {
    color: #0F1D23;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0.24rem 0 0.5rem;
}

.account-icons-subpartition-container {
    display: flex;
    height: min(calc(100% - 2.625rem), 13.75rem);
    flex-wrap: wrap;
    padding: 0.625rem;
    padding-right: 0;
    width: calc(100% - 1.25rem);
}

.account-icons-subpartition-title {
    align-items: center;
    color: #122E73;
    cursor: pointer;
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    user-select: none;
}

.account-icons-subpartition-title-name-container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.account-icons-add-subpartition-button {
    background-color: #FAFEFF;
    border: 0.125rem dashed #144066;
    border-radius: 0.25rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    padding: 0.25rem 0;
    width: 100%;
}

.account-icons-add-subpartition-button:hover {
    text-decoration: underline;
}

.account-icons-partition-icons-container {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
}

.account-icons-track-horizontal {
    background-color: transparent;
    left: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
}

.account-icons-track-vertical {
    background-color: transparent;
    top: 0.625rem;
    bottom: 0.625rem;
    height: 100%;
    right: 0;
}

.account-icons-thumb-horizontal {
    background-color: #C4DEF5;
    border-radius: 0.1875rem;
}

.account-icons-thumb-vertical {
    background-color: #C4DEF5;
    border-radius: 0.1875rem;
}

.account-icons-name-field {
    background-color: inherit;
    border: inherit;
    color: inherit;
    font: inherit;
    height: inherit;
    line-height: inherit;
    padding: inherit;
    width: 100%;
}