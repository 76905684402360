.tool-menu-text-box {
    background-color: #FAFEFF;
    border: 0px;
    border-radius: inherit;
    color: #144066;
    height: 30px;
    margin: 10px;
    margin-top: 0;
    padding: 0px 5px;
    width: 159px;
}

.tool-menu-text-box::selection {
    background: #C4DEF5;
}

.tool-menu-text-box::-moz-selection { /*FF 2-61*/
    background: #C4DEF5;
}
