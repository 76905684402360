.comment {
    display: grid;
    grid-template-rows: 1.5rem 2rem auto auto;
    grid-template-columns: 4rem auto minmax(6.5rem, auto) 6.625rem auto minmax(auto, 15rem);
    grid-column-gap: 1.25rem;
    grid-row-gap: 0.5rem;
    grid-template-areas:
        "ava name date rating . ."
        "ava comm comm comm comm comm"
        ". comm comm comm comm comm"
        " . btn btn . . .";
    margin-bottom: 1.875rem;
    min-height: 4rem;
    width: 40rem;
}

.ava {
    grid-area: ava;
}

.ava img {
    border-radius: 50%;
}

.name {
    color: #144066;
    display: inline;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
}

.rating {
    width: 6.625rem;
}

.comm {
    grid-column-start: 1;
    display: grid;
    align-items: baseline;
    font-family: OpenSans;
    width: 100%;
    overflow-x: auto;
    height: max-content;
    word-break: break-all;
}

.minmax {
    grid-area: comm;
    display: grid;
    overflow: hidden;
}

.buttonMinmax {
    grid-area: btn;
    display: grid;
    font-family: Roboto, sans-serif;
    font-size: inherit;
    color: #144066;
    font-weight: 500;
    cursor: pointer;
    width: 6rem;
    overflow: hidden;
}
