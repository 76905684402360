/*
    чтобы поменять размеры меню, надо менять:
    tool-menu.height: \d+\w+;
    tool-menu.width: \d+\w+;
*/

.tool-menu {
    position: fixed;
    height: 29rem;
    width: 6.75rem;
    background: #C4DEF5;
    border-radius: 0.625rem;
    z-index: 150;
    user-select: none;
}

.tool-menu-header {
    height: 1.3125rem;
    width: 6.25rem;
}

.tool-menu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0.25rem;
    width: calc(100% - 0.5rem);
    justify-content: flex-start;
}

.tool-menu-divider {
    background: #143D66;
    opacity: 0.4;
    border-radius: 0.25rem;
    height: 0.125rem;
    width: 5.5rem;
    margin: 0.25rem 0.625rem;
}

.tool-menu-submenu-title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    height: 0.75rem;
    color: #143D66;
    margin: -0.1875rem 0 0.25rem 0.625rem;
}
