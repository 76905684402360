.store-container {
    height: calc(100vh - 3.75rem);
    overflow: auto;
    width: 100%;
    margin: 3.75rem 0 0 0;
}

.react-select-clear-indicator-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
}