.select {
    color: #144066;
    font-family: Roboto, sans-serif;
}

.select div {
    color: inherit;
    padding: 0;
}

.select div[class*="Input"] {
    grid-area: 1/3/2/3;
    margin: 0 0 0 0.125rem;
}

.select div[class*="ValueContainer"] {
    flex-wrap: nowrap;
}

.select div[class*="input-container"] {
    grid-area: 1/3/2/3;
}

.select div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    min-height: auto;
    padding: 0.25rem 0 0.25rem 0.625rem;
    width: 100%;
}

.select div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.select div[class$="menu"] {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
}

.select div[class*="menu-list"] {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    margin: 0;
}

.select div[class*="placeholder"] {
    opacity: 0.6;
}

.select div[class*="value-container--has-value"] {
    flex-wrap: nowrap;
    opacity: 1;
}

.select div[class*="option"] {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    background-color: #FAFEFF;
    padding: 0.3125rem 0.625rem;
}

.select div[class*="option"]:hover {
    background-color: #C4DEF5;
}

.select div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.select div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.select div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}
