.tool-menu-item-wrapper {
    background: #FAFEFF;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    margin: 5px 4px;
}

.tool-menu-switcher-wrapper {
    background: #FAFEFF;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    height: 40px;
    width: 40px;
    margin: 5px 4px;
}

.tool-menu-item {
    background-size: contain;
    height: 30px;
    width: 30px;
    margin: 5px;
}

.tool-menu-item-wrapper:hover, .tool-menu-item-wrapper-composite:hover, .tool-menu-switcher-wrapper:hover {
    border: 2px solid #FF983D;
    margin: 3px 2px;
}

.tool-menu-item-wrapper-active {
    background-color: #FF983D;
}

.tool-menu-item-title {
    color: #144066;
    margin: 10px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
}

.tool-menu-item-body {
    position: fixed;
    min-width: 189px;
    /* padding: 5px; */
    background-color: #C4DEF5;
    border: 1px solid #144066;
    border-radius: 10px;
}

.tool-menu-switcher-item-wrapper {
    background: #FAFEFF;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin: 0px 4px;
}

.tool-menu-switcher-item-wrapper:hover {
    border: 2px solid #FF983D;
    margin: -2px 2px;
}

.tool-menu-switcher-item-wrapper:active {
    background-color: #FF983D;
}


.tool-menu-switcher-body {
    position: fixed;
    background-color: #C4DEF5;
    border: 1px solid #144066;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px 1px;
    margin-top: -10px;
}
