.wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.flex {
    border-radius: 0.625rem;
}