.context-menu {
    background: #C4DEF5;
    border-radius: 10px;
    border: 1px solid #144066;
    margin-top: 0px;
    margin-left: 0px;
    padding: 5px;
    position: absolute;
    z-index: 200;
}

.context-menu-row {
    display: flex;
    flex-direction: row;
}

.context-menu-button {
    margin: 5px;
    padding-bottom: 3px;
}

.context-menu-tool-wrapper {
    position: relative;
}

.context-menu-color-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.github-picker {
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden;
    padding: 0px !important;
    width: 175px !important;

}

.context-menu-emoji-picker {
    background: none;
    padding: 5px;
    width: 170px;
}

.context-menu-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 0px;
    border-radius: 10px;
    background-color: none;
}

.context-menu-slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
    min-width: 30px;
}

.context-menu-slider {
    width: 160px !important;
}
