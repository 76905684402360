.top-menu {
    user-select: none;
    font-family: Roboto, sans-serif;
}

.tool-menu-background {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: none;
    z-index: 150;
}

.top-menu-wrapper {
    grid-row: menu;
    display: flex;
}

.top-menu-container {
    color: #144066;
    user-select: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    border: 0px;

    background-color: #9CC6EA;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.top-menu-title {
    background-color: #9CC6EA;
    color: #144066;
    padding: 9px 20px;
    font-size: 18px;
}

.top-menu-progress-bar {
    background-color: #9CC6EA;
    color: #144066;
    padding: 5px 0px;
    font-size: 14px;
}

.top-menu-pseudo-title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #144066;
    border-bottom: 1px solid #C4DEF5;
    background-color: #C4DEF5;
    padding: 8px 19px 5px 19px;
    line-height: 26px;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    z-index: 300;
}

.top-menu-pseudo-title-rounded {
    border: 1px solid #144066;
    border-radius: 10px;
    background-color: #C4DEF5;
    padding: 8px 19px 5px 19px;
    line-height: 26px;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    z-index: 300;
}

.top-menu-item {
    background-color: #C4DEF5;
    border-radius: 10px;
    border-top-left-radius: 0px;
    display: flex;
    flex-direction: column;
    border: 1px solid #144066;
    min-width: 120px;
    z-index: 200;

    position: absolute;
}

.top-menu-subitem, .top-menu-hot-key {
    padding: 14px;
    margin: 0px;
    font-size: 16px;
    opacity: 0.8;
}

.top-menu-subitem-disabled, .top-menu-hot-key-disabled {
    padding: 14px;
    margin: 0px;
    font-size: 16px;
    opacity: 0.3;
    pointer-events: none;
}

.top-menu-subitem:hover {
    font-weight: 600;
    opacity: 1;
}

.top-menu-subitem-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.map-title-wrapper {
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
}

.map-title-header {
    margin: 8px 0px;

    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #144066;
}

.map-title-input {
    margin: 6px 0px 4px 0px;

    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #144066;
    text-align: center;

    background-color: #C4DEF5;
    border: none;
    border-radius: 6px;

    outline: none;

    max-width: 480px;
}

.top-menu-auth-button {
    padding: 0 20px;
    margin: 9px 0;
    cursor: pointer;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #122E73;
}

.top-menu-auth-button:hover {
    font-weight: 600;
}

.top-menu-auth-button+.top-menu-auth-button {
    padding-left: 0;
}

.top-menu-auth-button+.top-menu-auth-button::before {
    content: "|";
    display: inline-block;
    font-size: 1.25rem;
    line-height: 0.875rem;
    margin-right: 1.25rem;
    opacity: 0.7;
    transform: scaleX(0.5);
}

.top-menu-user-name-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
}

.top-menu-user-name-container span {
    color: #144066;
}

.top-menu-user-name-container span:hover {
    font-weight: 500;
}


.top-menu-avatar-wrapper {
    margin-left: 10px;
}
