.body {
    display: grid;
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    color: #D9E1F6;
    width: 100%;
    background-color: #FAFEFF;
}

.refs {
    display: flex;
    justify-content: space-between;
    max-width: 39.75rem;
    padding: min(3vh, 2.5rem) 0 min(3vh, 1.875rem) 5rem;
}

.content {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-style: normal;
    font-weight: initial;
    font-size: 1.5rem;
    margin: 0 0 2rem 0;
    padding: 0 0 0 5.625rem;
}

.products {
    width: 100%;
    margin: 0 5.625rem 2rem 3.0625rem;
    min-height: 21rem;
}

.category .title {
    color: #144066;
    display: flex;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    justify-content: space-between;
    line-height: 1.75rem;
    margin-bottom: 0.75rem;
}

.category .title a {
    color: #122E73;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.cards {
    display: grid;
    background-size: contain;
    margin: 0 0 3.85rem 0;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(auto-fit, 13.75rem);
}

.product-card-image {
    align-items: flex-start;
    border-radius: 0.625rem;
    display: flex;
    justify-content: flex-end;
    height: 8.125rem;
    width: 12.5rem;
}

.product-card-version-text {
    background-color: rgba(196, 222, 245, 0.85); /*#C4DEF5*/
    border-radius: 0.25rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    margin-left: 0.5rem;
    padding: 0.25rem;
}