.description-window-invisible-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    pointer-events: none;
    position: absolute;
    width: 250px;
    z-index: 2000;
}

.description-window {
    background: #C4DEF5;
    border: 1px solid #144066;
    border-radius: 10px;
    max-width: 100%;
    padding: 10px 20px;
    pointer-events: none;
}

.description-window-title {
    color: #144066;
    margin: 0px;
    text-align: center;
    width: 100%
}

.description-window-text {
    color: #144066;
    margin: 0px;
    margin-top: 10px;
    /* word-break: break-all; */
    /* word-wrap:break-word; */
    /* white-space: normal; */
    /* line-break: loose; */
    overflow-wrap:anywhere;
}

@keyframes show {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes hide {
  from {opacity: 1;}
  to {opacity: 0;}
}
