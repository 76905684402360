.input {
    background: none;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    box-sizing: border-box;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 1.5rem;
    padding: 0 0 0 0.625rem;
    width: 100%;
}

.input::placeholder {
    color: #144066;
    opacity: 0.6;
}

.input::-moz-placeholder {
    color: #144066;
    opacity: 0.6;
}

.input::-webkit-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.input::-ms-input-placeholder {
    color: #144066;
    opacity: 0.6;
}

.input:focus {
    border: none;
    background-color: #C4DEF5;
    outline: none;
}

.icon {
    grid-area: 1 / 1 / 1 / 1;
    position: relative;
    align-self: center;
    left: calc(100% - 2.125rem);
    height: auto;
    width: auto;
}

.input[type=file] {
    display: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* FF */
}
