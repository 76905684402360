.tool-menu-text-area {
    background-color: #FAFEFF;
    border: 0px;
    border-radius: inherit;
    color: #144066;
    font-size: 3.3mm;
    height: 110%;
    margin: 10px;
    margin-top: 0;
    padding: 5px 5px;
    resize: none;
    width: 159px;
}

.tool-menu-text-area::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.tool-menu-text-area::-moz-placeholder { /*FF 19-50*/
    color: rgba(20, 64, 102, 0.6);
}

.tool-menu-text-area::-webkit-placeholder { /*Chrome 4-56 and other webkit-based*/
    color: rgba(20, 64, 102, 0.6);
}

.tool-menu-text-area::selection {
    background: #C4DEF5;
}

.tool-menu-text-area::-moz-selection { /*FF 2-61*/
    background: #C4DEF5;
}
