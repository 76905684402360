.research-link-to-studio {
    color: #144066;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
}

.research-tab-hidden-input {
    display: none;
}

.research-tab-label {
    background-color: #C4DEF5;
    border-radius: 1rem;
    cursor: pointer;
    display: inline-block;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    user-select: none;
}

.research-tab-hidden-input:checked+.research-tab-label {
    text-decoration: underline;
}

#research-tab-hidden-input-main:checked~#main-research-container {
    display: flex;
}

#research-tab-hidden-input-wfpm:checked~#main-research-other-container {
    display: flex;
}

.main-research-container {
    align-items: flex-start;
    display: none;
    justify-content: flex-start;
}

#main-research-other-container {
    align-items: flex-start;
    display: none;
    justify-content: flex-start;
    margin: 1rem;
}

.research-table-container {
    height: calc(100vh - 6rem);
    margin: 2rem 0.5rem 2rem 2rem;
    overflow: auto;
    width: 24rem;
}

.research-table-showing-handler {
    height: calc(100vh - 6rem);
    margin: 2rem 2rem 0 0;
}

.research-confine-mode {
    color: black;
    cursor: pointer;
}

.research-confine-mode-active {
    color: #144066;
    cursor: pointer;
    text-decoration: underline;
}

.research-table-cell-date {
    min-width: 6rem;
}

.research-table-cell-blue {
    background-color: #C4DEF5;
    min-width: 4rem;
    max-width: 8rem;
}

.research-exclude-user-textbox {
    width: 5rem;
}

.research-table-update-container {
    display: flex;
    margin: 0.5rem;
}

.research-table-update-button {
    margin-right: 0.5rem;
}

.research-table-create-link {
    background: none;
    border: none;
    cursor: pointer;
    user-select: text;
}

.dashed-container {
    border: 0.15rem dashed black;
    border-radius: 2rem;
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.research-data-downloader-partition-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
}

.research-source-node-configurator-container {
    grid-column: 1 / 3;
    height: 15rem;
}

.research-source-node-configurator-grid {
    border-bottom: 0.15rem dashed black;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem 1rem;
    padding-bottom: 1rem;
}

.research-source-node-configurator-option-container {
    display: flex;
    flex-direction: column;
}

.research-source-node-configurator-server-map-selector {
    display: flex;
    flex-direction: column;
}

.research-source-node-configurator-local-data-container {
    align-items: center;
    display: flex;
    grid-column: 2/4;
}

.research-source-node-configurator-local-data-container label {
    font-size: 0.75rem;
}

.research-source-node-configurator-data-loader {
    width: 7.5rem;
}

.research-source-node-configurator-custom-map-list {
    width: 10rem;
}

.research-data-type-container {
    border-bottom: 0.15rem dashed black;
}

[id^=research-table-cell-] {
    cursor: pointer;
}
