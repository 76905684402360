.svg-canvas-container {
    grid-row: canvas;

    background-color: inherit;
    overflow: auto;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    min-height: 10px;
    height: 100%;
    border-radius: 10px;
    z-index: 100;
    user-select: none;
}

.svg-canvas {
    background-color: #FAFEFF;
    border-radius: 10px;
    outline: none;
}

.figure, .text {
    opacity: 1;
}

g.figure:hover, .text:hover {
    opacity: 0.7;
}

.group:hover .figure {
    opacity: 0.7;
}

.figure-neighborhood:hover+.figure {
    opacity: 0.7;
}


.emoji-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row;
    pointer-events: none;
    height: 100%;
}

@keyframes highlight {
    0%   {stroke-width: 1mm; stroke: yellow;}
    25%  {stroke-width: 2mm; stroke: orange;}
    50%  {stroke-width: 3mm; stroke: red;}
    100% {stroke-width: 1mm; stroke: yellow;}
}

.blue-fill {
    /* fill:#82c4ec; */
}

.no-fill {
    fill:none !important;
}

.white-fill {
    fill:#fff !important;
}

.weak-stroke {
    stroke-width:1.33px;
    stroke-linejoin:round;
}

.normal-stroke {
    stroke-width:2px;
    stroke-linejoin:round;
}

.strong-stroke {
    stroke-width:3px;
    stroke-linejoin:round;
}

.empty {
    /* fill: red; */
}

/* scroll */

.view {
    background-color: none;
}

.track-horizontal {
    background-color: none;
    left: 10px;
    right: 10px;
    bottom: 10px;
}

.track-vertical {
    background-color: none;
    top: 10px;
    bottom: 10px;
    height: 95%;
    right: 10px;
}

.thumb-horizontal {
    background-color: #C4DEF5;
    border-radius: 3px;
}

.thumb-vertical {
    background-color: #C4DEF5;
    border-radius: 3px;
}

.hint-connector {
    pointer-events: none;
}

.symbol-text, .symbol-emojis {
    font-weight: 600;
    stroke-width: 0.2mm;
    font-family: AnonymousPro;
    user-select: none;
}


/* flags */

.point-feature-flag-pink {
    cursor: pointer;
    fill: pink;
    stroke: pink;
}

.point-feature-flag-red {
    cursor: pointer;
    fill: red;
    stroke: red;
}

.point-feature-flag-green {
    cursor: pointer;
    fill: green;
    stroke: green;
}

.point-feature-flag-blue {
    cursor: pointer;
    fill: blue;
    stroke: blue;
}

.point-feature-flag-black {
    cursor: pointer;
    fill: black;
    stroke: black;
}

.point-feature-cross {
    cursor: pointer;
    fill: #144066;
}

.point-feature-cross:hover {
    fill: #FF983D;
}
