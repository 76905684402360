.tool-menu-emoji-picker, .tool-menu-emoji-container {
    border-radius: inherit;
    background: none;
    width: 235px;
}

.tool-menu-emoji-container {
    padding-top: 4px;
    padding-left: 4px;
}

.emoji-mart {
    width: 100% !important;
    background: none;
    border: none;
}

.emoji-mart-scroll {
    max-height: 200px;
}

.emoji-mart-category-label>span {
    background: #C4DEF5;
    color: #144066;
}

.emoji-mart-search {
    display: none;
}

.emoji-mart-bar {
    border: none;
}

.emoji-mart-anchor {
    padding: 8px 0px;
}

.emoji-mart-anchor-icon svg {
    transform: scale(0.8);
}

.emoji-mart-anchor-bar {
    background-color: #144066 !important;
}
