.ReactModal__Overlay--after-open {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Content--after-open {
    left: initial !important;
    top: initial !important;
    right: initial !important;
    bottom: initial !important;
}

.modal-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    outline: none;
    overflow: hidden;
}

.modal-container a {
    color: #144066;
    text-decoration: underline;
}

.modal-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 42px;
    margin-top: 2px;
    text-align: center;
}

.modal-survey-title {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 2px 15px 25px;
    max-width: 500px;
    text-align: center;
}

.modal-p {
    color: #122E73;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
}

.modal-resize-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 290px;
    height: 40px;
    margin-left: 11px;
    margin-bottom: 20px;
}

.modal-resize-input {
    height: 20px;
    width: 185px;
    border-radius: 10px;
    padding: 10px;
    border: none;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    background-color: #C4DEF5;
    color: rgba(20, 64, 102, 1);

}

.modal-resize-input:focus {
    background: #FAFEFF;
}

.modal-resize-input::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.modal-resize-input:focus::placeholder {
    color: rgba(20, 64, 102, 0.6);
}

.modal-button-exit {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    padding: 0;
    border: none;
    background: none;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
    cursor: pointer;
}

.modal-resize-button-exit {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 16px;
    height: 16px;
    border: none;
    background: none;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
    cursor: pointer;
}

.modal-button-exit:focus, .modal-resize-button-exit:focus, .modal-button-apply:focus, .modal-resize-input:focus {
    outline: none;
}

.modal-button {
    background-color: #143D66;
    border-radius: 10px;
    border: none;
    color: #FAFEFF;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    height: 45px;
    text-align: center;
}

.modal-button-apply {
    letter-spacing: 0.05em;
    margin-top: 23px;
    text-transform: uppercase;
    width: 320px;
}

.modal-button-survey {
    margin-bottom: 23px;
    margin-top: 23px;
    width: 320px;
}

.modal-button-survey-question-next {
    margin-bottom: 23px;
    margin-top: 23px;
    width: 150px;
}

.modal-button-survey-question-prev {
    margin-bottom: 23px;
    margin-right: 10px;
    margin-top: 23px;
    width: 150px;
}

.my-maps {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: 100px;
}

.my-maps-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.my-maps-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
}

.my-maps-selection-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.my-maps-delete {
    background-color: #144066;
    border: none;
    border-radius: 0.5rem;
    color: #FAFEFF;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    margin-left: 1rem;
    outline: none;
    padding: 0.25rem 0.75rem;
    width: 8rem;
}

.my-maps-delete:hover {
    background-color: #FAFEFF;
    color: #144066;
}

.my-maps-delete:active {
    background-color: #FAFEFF;
    border: 0.1rem solid #144066;
    padding: 0.15rem 0.65rem;
    color: #144066;
}

.my-maps-partition-title {
    color: #144066;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin: 1rem 0 0.5rem;
}

.my-maps-no-maps-container {
    color: rgba(20, 64, 102, 0.6);
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: 40px;
    margin-top: 25%;
    text-align: center;
    width: 100%;
}

.my-maps-track-horizontal {
    background-color: none;
    left: 10px;
    right: 10px;
    bottom: 10px;
}

.my-maps-track-vertical {
    background-color: none;
    top: 10px;
    bottom: 10px;
    height: 95%;
    right: 0;
}

.my-maps-thumb-horizontal {
    background-color: #9CC6EA;
    border-radius: 3px;
}

.my-maps-thumb-vertical {
    background-color: #9CC6EA;
    border-radius: 3px;
}

.map-preview {
    border: #144066 solid thin;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.map-preview-header {
    align-items: flex-start;
    background-color: rgba(196, 222, 245, 0.8); /*#C4DEF5 with 0.8 transparency*/
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    color: #144066;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
}

.map-preview-delete, .map-preview-check {
    border: none;
    cursor: pointer;
    height: 12px;
    margin-top: 0.125rem;
    min-width: 12px;
    padding-top: 0;
    width: 12px;
}

.map-preview-delete {
    background-color: transparent;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
}

.map-preview-check {
    background-color: transparent;
    background-image: url(../../../studio/images/extraWindow/cross.svg);
    background-size: contain;
}

.modal-background-container {
    display: grid;
    grid-template-areas: "title" "radio" "prevent" "apply";
    grid-template-rows: 4.25rem 3.75rem 3.75rem 4.25rem;
}

.modal-background-title {
    grid-area: title;
}

.modal-background-radio-group {
    grid-area: radio;
    margin-left: 0.625rem;
}

.modal-background-check-prevent {
    align-items: center;
    display: flex;
    grid-area: prevent;
    justify-content: flex-start;
    margin-left: 0.625rem;
}


.survey-answer-checkbox-hidden-std {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.survey-answer-checkbox-label {
    color: rgba(20, 64, 102, 1);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
}

.survey-answer-checkbox-hidden-std+.survey-answer-checkbox-label::before {
    background-color: #9CC6EA;
    border-radius: 20%;
    content: '';
    display: inline-block;
    height: 1rem;
    margin-bottom: -0.1rem;
    margin-right: 0.7rem;
    width: 1rem;
}

.survey-answer-checkbox-hidden-std:checked+.survey-answer-checkbox-label::before {
    background-color: #144066;
    border: 0.2rem solid #9CC6EA;
    height: 0.6rem;
    width: 0.6rem;
}


.modal-background-apply {
    align-items: center;
    display: flex;
    grid-area: apply;
    height: 2.5rem;
    justify-content: center;
    margin-bottom: 1.25rem;
}
