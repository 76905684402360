.hint-mode-beacon {
    border-radius: 0%;
    display: inline-block;
    height: 2rem;
    width: 1rem;
    color: #144066;
    font-size: 1.5rem;
    font-weight: 900;
    cursor: help;
    pointer-events: all;
    z-index: 900;
}

/* .react-tooltip-lite-arrow {
  color: #144066;
} */

.tooltip-content {
    border: 1px solid #144066;
    background: #C4DEF5;
    color: #144066;
    border-radius: 10px;
    padding: 10px;
    z-index: 1200;
    display: block;
    white-space: normal;
    max-width: 350px;
    pointer-events: none;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: show;
}

.no-pointer-events-tooltip .react-tooltip-lite {
    pointer-events: none;
}
