.tabs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.tab {
    background: transparent;
    border-radius: 0.625rem;
    border-width: 0;
    box-shadow: none;
    color: #144066;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    height: auto;
    margin-right: 1rem;
    padding: 0.3125rem 0.625rem;
    text-decoration: none;
}

/*.tab:hover {*/
/*    background-color: #C4DEF5;*/
/*    outline: none;*/
/*}*/

.tab:focus {
    background-color: #C4DEF5;
    outline: none;
}

.tab.active {
    background-color: #C4DEF5;
    color: #144066;
    outline: none;
}

.content {

}
