.footer {
    bottom: 0;
    color: #074479;
    display: flex;
    flex-direction: row;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    grid-row: social;
    justify-content: space-between;
    line-height: 1.5rem;
    padding: 0.3125rem;
}

.footer-sharing-container {
    display: flex;
    flex-direction: row;
}

.share-container {
    display: flex;
    flex-direction: row;
}

.footer-icon-wrapper {
    background-color: #C4DEF5;
    border-radius: 0.375rem;
    height: 1.5rem;
    margin: 0 0.3125rem;
    padding: 0.1875rem;
    width: 1.5rem;
}

.footer-icon-wrapper:hover {
    border: 0.125rem solid #143D66;
    padding: 0.0625rem;
}

.footer-studio-name {
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.5rem;
    margin: 0.1875rem 0 0 0.625rem;
}

.footer-company-name {
    font-size: 0.8175rem;
    height: 1.5rem;
    margin: 0.1875rem 0 0 0.25rem;
}

.footer-channel-link {
    color: #074479;
    font-size: 0.8175rem;
    height: 1.5rem;
    margin: 0.1875rem 0.5rem 0 0;
}

a:first-child .footer-channel-link {
    margin-right: 1rem;
}

.footer-channel-link:hover, .footer-channel-link:active {
    font-weight: 500;
}

.footer-share-wrapper {
    display: flex;
    margin: 0 0.625rem;
}

.studio-footer-share-field {
    background-color: #C4DEF5;
    border-radius: 0.375rem;
    color: #144066;
    font-family: Roboto, sans-serif;
    margin: 0 0.3125rem;
    width: 18.75rem;
}

.studio-footer-share-field div {
    color: inherit;
    padding: 0;
}

.studio-footer-share-field div[class$="-control"] {
    background-color: transparent;
    background-repeat: repeat;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: none;
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: auto;
    padding: 0.0625rem 0 0.0625rem 0.625rem;
    width: 100%;
}

.studio-footer-share-field div[class*="control--is-focused"] {
    background-color: #C4DEF5;
}

.studio-footer-share-field div[class$="menu"] {
    background-color: #FAFEFF;
    border: 0.125rem solid #C4DEF5;
    border-radius: 0.625rem;
    line-height: 2rem;
    min-height: 2rem;
    z-index: 3000;
}

.studio-footer-share-field div[class*="menu-list"] {
    background-color: #FAFEFF;
    border-radius: 0.625rem;
    margin: 0;
}

.studio-footer-share-field div[class*="placeholder"] {
    opacity: 0.6;
}

.studio-footer-share-field div[class*="value-container--has-value"] {
    flex-wrap: nowrap;
    opacity: 1;
}

.studio-footer-share-field div[class*="option"] {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    background-color: #FAFEFF;
    padding: 0.3125rem 0.625rem;
}

.studio-footer-share-field div[class*="option"]:hover {
    background-color: #C4DEF5;
}

.studio-footer-share-field div[class*="option"]:first-child {
    padding-top: 0.625rem;
}

.studio-footer-share-field div[class*="option"]:last-child {
    padding-bottom: 0.625rem;
}

.studio-footer-share-field div[class*="clear-indicator"] {
    margin-right: 0.5rem;
}

