input[type=range] {
  height: 20px;
  -webkit-appearance: none;
  margin: 2px 0;
  background-color: #C4DEF5;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 128px;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #FAFEFF;
  border-radius: 10px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #FAFEFF;
}

input[type=range]::-moz-range-track {
  width: 128px;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #FAFEFF;
  border-radius: 10px;
}

input[type=range]::-ms-track {
  width: 128px;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  height: 14px;
  width: 6px;
  border-radius: 10px;
  background: #144066;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}

input[type=range]::-moz-range-thumb {
  height: 14px;
  width: 6px;
  border-radius: 6px;
  background: #144066;
  cursor: pointer;
}

input[type=range]::-ms-fill-lower {
  background: #FAFEFF;
  border: 1px solid #144066;
  border-radius: 50px;
  box-shadow: 0 0 0 #000000;
}
input[type=range]::-ms-fill-upper {
  background: #FAFEFF;
  border: 1px solid #144066;
  border-radius: 50px;
  box-shadow: 0 0 0 #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #144066;
  border: 1px solid #144066;
  height: 24px;
  width: 35px;
  border-radius: 6px;
  background: #144066;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #FAFEFF;
}
input[type=range]:focus::-ms-fill-upper {
  background: #FAFEFF;
}

.tool-menu-slider-title {
    color: #074479;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0;
    min-width: 30px;
}

.tool-menu-slider-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    background-color: transparent;
}

.tool-menu-slider {
    width: 8rem;
}

.base-block-slider {
    width: 13rem;
}
