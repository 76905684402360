.title {
    display: flex;
    background-color: #E0F1FF;
    height: min(10vh, 6.25rem);
    align-items: center;
    padding-left: 5.6rem;
    user-select: none;
}

.name {
    color: #144066;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 0;
    white-space: normal;
}

.desc {
    color: #144066;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 0.2rem;
}
