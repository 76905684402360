.radio {
    display: flex;
    width: 1rem;
    height: 1rem;
    position: relative;
}

.input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
