.app {
    display:flex;
    flex-direction: column;
    background-color: #9CC6EA;
    justify-content: space-around;
    height: 100%;
}

.main-container {
    display:grid;
    grid-template-rows: [menu] 40px [toolbar] 150px [canvas] calc(100vh - 230px) [social] 40px;
}

.editor {
    height: 88vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.text-disabled {
    color: rgba(20, 64, 102, 0.3) !important; /* #144066 with 0.3 opacity */
    pointer-events: none;
}

.react-gravatar {
    border-radius: 6px;
}
